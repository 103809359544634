.projects {
  margin: 0 auto;
  max-width: 1800px;
  /* display: flex; */
  /* flex-wrap: wrap; */
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;
  justify-content: center;
  margin-bottom: 64px;
}

.work-summary {
  font-family: 'Lato', sans-serif;
  font-size: var(--font-lg);
  line-height: var(--line-height-lg);
  color: var(--text);
  margin: 16px 0 48px 0;
  animation-name: fade-in;
  animation-duration: 1s;
}

.work-section-title {
  text-transform: uppercase;
  font-family: 'Syne', sans-serif;
  font-size: var(--font-xxl);
  line-height: var(--line-height-xxl);
  animation-name: fade-in;
  animation-duration: 1s;
}

@media (max-width: 1050px) {
  .projects {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 680px) {
  .projects {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media (max-width: 576px) {
  .work-section-title {
    font-size: 38px;
    line-height: 38px;
    margin-bottom: 16px;
  }

  .work-summary {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 32px;
  }
}