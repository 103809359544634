.header {
  display: flex;
  justify-content: space-between;
}

.title-container {
  z-index: 999;
  text-transform: uppercase;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 12px;
}

.title {
  font-family: 'Raleway', sans-serif;
  font-weight: 600;
  font-size: 42px;
}

.first-name, .last-name {
  transition: all 0.3s;
}

.last-name {
  color: var(--background);
  background-color: var(--off-white);
  padding: 0 10px;
}

.title:hover > .first-name {
  color: var(--accent);
}

.title:hover > .last-name {
  background-color: var(--accent);
}

.subtitle {
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  letter-spacing: 4px;
}

.navigation, .navigation-mobile {
  font-family: 'Syne', sans-serif;
  font-weight: 400;
  font-size: 18px;
  text-transform: uppercase;
  display: flex;
  gap: 48px;
}

.navigation {
  flex-direction: row;
}

.navigation-mobile {
  flex-direction: column;
  align-items: flex-end;
  font-size: 32px;
}

.navigation > a {
  position: relative;
}

.navigation > a::before {
  content:'❯ ';
  transition: .3s;
  position: absolute;
  font-weight: 600;
  line-height: 24px;
  left: -18px;
  opacity: 0;
  color: var(--accent);
}

.navigation > a:hover::before {
  left: -14px;
  opacity: 1;
}

.menu-icon {
  font-size: 32px;
}

@media (max-width: 768px) {
  
  .title {
    font-size: 28px;
  }

  .subtitle {
    font-size: 12px;
    letter-spacing: 4px;
  }

  .title-container {
    gap: 6px;
  }
}