.menu-overlay {
  position: fixed;
  box-sizing: border-box;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: var(--background-half-opacity);
  z-index: 99;
  backdrop-filter: blur(4px);
  padding: 32px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 64px;
  animation-name: fade-in-with-blur;
  animation-duration: .3s;
}

@media (max-width: 576px) {
  .menu-overlay {
    padding: 12px;
  }
}

@keyframes fade-in-with-blur {
  from {
    opacity: 0;
    backdrop-filter: blur(0px);
  }

  to {
    opacity: 1;
    backdrop-filter: blur(4px);
  }
}