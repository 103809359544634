@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@400;500;600&family=Lato:wght@400;700&family=Raleway:wght@300;400;600;700&family=Source+Code+Pro&family=Syne:wght@700&display=swap');

:root {
  --background: #001b1e;
  --background-half-opacity: #001b1e83;
  --background-full-opacity: #001b1eFF;
  --eerie-black: #23242C;
  --off-white: #ebf8fa;
  --blue-green: #38bdcc;
  --light-gray: #989ca3;
  --medium-gray: #10383d;
  --bright-red: #ed230c;

  --primary: var(--blue-green);
  --secondary: var(--medium-gray);
  --accent: var(--bright-red);
  --text: var(--light-gray);

  --font-xs: 12px;
  --line-height-xs: 12px;

  --font-sm: 16px;
  --line-height-sm: 20px;

  --font-md: 18px;
  --line-height-md: 24px;

  --font-lg: 22px;
  --line-height-lg: 32px;

  --font-xl: 32px;
  --line-height-xl: 42px;

  --font-xxl: 48px;
  --line-height-xxl: 60px;
  
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-semibold: 600;
  --font-weight-bold: 700;

  --standard-transition: all 0.3s;
}

body {
  margin: 32px;
  font-family: 'Raleway', sans-serif;
  font-size: var(--font-md);
  font-weight: var(--font-weight-regular);
  background-color: var(--background);
  color: var(--off-white);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: inherit;
  text-decoration: inherit;
}

.highlight {
  color: var(--accent);
}

.link {
  text-decoration: underline;
  text-decoration-color: var(--blue-green);
  transition: all 0.3s;
}

.link:hover {
  color: var(--accent);
  text-decoration-color: var(--accent);
}

.container {
  max-width: 1200px;
  margin: 48px auto;
}


@media (max-width: 576px) {
  body {
    margin: 12px;
  }

  .container {
    margin: 32px auto;
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes slide-in-from-left {
  from {
    opacity: 0;
    transform: translateX(-32px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slide-in-from-right {
  from {
    opacity: 0;
    transform: translateX(32px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}


@keyframes slide-in-from-bottom {
  from {
    opacity: 0;
    transform: translateY(32px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slide-in-from-top {
  from {
    opacity: 0;
    transform: translateY(-32px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}