.project-panel {
  box-sizing: border-box;
  display: inline-block;
  overflow: hidden;
  position: relative;
  max-width: 100%;
  border-radius: 16px;
  box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
  animation-name: slide-in-from-bottom;
  animation-duration: 1s;
}

.project-panel:hover {
  box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
}

.project-panel-image {
  transition: var(--standard-transition);
  width: 100%;
  z-index: -1;
  filter: blur(0px);
}

.project-panel:hover > .project-panel-image {
  filter: blur(4px);
}

.project-panel:hover > .project-panel-text-content {
  text-shadow: 1px 1px 6px black;
}

.project-panel-content {
  transition: var(--standard-transition);
  background: linear-gradient(var(--background-half-opacity), var(--background-full-opacity));
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: calc(100% - 32px);
  width: calc(100% - 32px);
  bottom: 0px;
  left: 0px;
  padding: 16px;
  text-shadow: 1px 1px 6px black;
}

.project-panel-title {
  text-transform: uppercase;
  font-family: 'Syne', sans-serif;
  font-size: var(--font-lg);
  font-size: var(--line-height-lg);
  font-weight: 700;
  margin-bottom: 12px;
}

.project-panel-description {
  font-family: 'Lato', serif;
  /* font-style: italic; */
  font-weight: 300;
  font-size: var(--font-md);
  line-height: var(--line-height-md);
}

.project-panel-tags {
  display: flex;
  column-gap: 8px;
  row-gap: 8px;
  flex-wrap: wrap;
}

.project-panel-tag {
  white-space: nowrap;
  background-color: var(--off-white);
  color: var(--eerie-black);
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
  font-size: var(--font-xs);
  line-height: var(--line-height-xs);
  height: 12px;
  font-weight: 700;
  font-family: 'Raleway', sans-serif;
  text-transform: uppercase;
  text-shadow: none;
  padding: 4px 6px;
  border-radius: 4px;
}

.project-panel-bottom-section {
  margin-top: auto;
}

.project-panel-buttons {
  display: flex;
  justify-content: flex-start;
  text-shadow: none;
  text-transform: uppercase;
  font-weight: 700;
  font-family: 'Raleway', sans-serif;
  margin-bottom: 24px;
  padding: 0;
  gap: 12px;
  white-space: pre;
}

.project-button {
  position: relative;
  transition: var(--standard-transition);
  box-sizing: border-box;
  color: black;
  background-color: white;
  height: 48px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 8px;
  border-radius: 4px;
}

.project-icon {
  font-size: 32px;
}

.project-button:hover, .github-button:hover {
  background-color: var(--accent);
  color: white;
}


@media (max-width: 1050px) {
  .project-panel-title {
    font-size: var(--font-xl);
    line-height: var(--line-height-xl);
  }

  .project-panel-description {
    font-size: var(--font-lg);
    line-height: var(--line-height-lg);
  }

  .project-panel-tag {
    font-size: var(--font-sm);
    line-height: var(--line-height-sm);
    height: 18px;
    padding: 6px 10px;
  }
}

@media (max-width: 850px) {
  .project-panel-title {
    font-size: var(--font-lg);
    line-height: var(--line-height-lg);
  }

  .project-panel-description {
    font-size: var(--font-md);
    line-height: var(--line-height-md);
  }

  .project-panel-tag {
    font-size: var(--font-xs);
    line-height: var(--line-height-xs);
    height: 12px;
    padding: 4px 6px;
  }
}

@media (max-width: 680px) {
  .project-panel-title {
    font-size: var(--font-xl);
    line-height: var(--line-height-xl);
  }

  .project-panel-description {
    font-size: var(--font-lg);
    line-height: var(--line-height-lg);
  }

  .project-panel-tag {
    font-size: var(--font-sm);
    line-height: var(--line-height-sm);
    height: 18px;
    padding: 6px 10px;
  }
}

@media (max-width: 450px) {
  .project-panel-title {
    font-size: var(--font-lg);
    line-height: var(--line-height-lg);
  }

  .project-panel-description {
    font-size: var(--font-md);
    line-height: var(--line-height-md);
  }

  .project-panel-tag {
    font-size: var(--font-xs);
    line-height: var(--line-height-xs);
    height: 12px;
    padding: 4px 6px;
  }
}