.content-block {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.animated {
  animation-name: slide-in-from-right;
  animation-duration: 1s;
}

.heading {
  font-family: 'Syne', sans-serif;
  font-size: var(--font-xl);
  font-weight: 700;
  line-height: var(--line-height-lg);
  text-transform: uppercase;
  margin-bottom: 8px;
}

.subheading {
  font-family: 'Syne', sans-serif;
  font-size: var(--font-lg);
  font-weight: 700;
  line-height: var(--line-height-lg);
  text-transform: uppercase;
  margin-top: 8px;
}

.content-paragraph {
  color: var(--text);
  font-family: 'Lato', sans-serif;
  font-size: var(--font-md);
  font-weight: var(--font-weight-regular);
  line-height: var(--line-height-md);
}

.bold-text {
  font-weight: 700;
}

.content-button {
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-family: 'Syne', sans-serif;
  font-size: var(--font-lg);
  font-weight: var(--font-weight-semibold);
  transition: var(--standard-transition);
  color: var(--background);
  background-color: white;
  height: 48px;
  cursor: pointer;
  margin: 24px 0;
  border-radius: 4px;
}

.content-button:hover {
  background-color: var(--accent);
  color: white;
}

.content-button-text-accent {
  margin-left: 12px;
}

@media (max-width: 768px) {
  .content-paragraph {
    font-size: var(--font-sm);
    line-height: var(--line-height-sm);
  }
  
  .content-block {
    gap: 6px;
  }

  .animated {
    animation-name: slide-in-from-bottom;
  }
}