.hero-image {
  animation-name: slide-in-from-right;
  animation-duration: 1s;
}

.home-content-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 48px;
  row-gap: 24px;
  width: 100%;
  margin: 96px 0 0 0;
}

.home-content-container > * {
  width: 100%;
}

@media (max-width: 768px) {
  .hero {
    font-size: var(--font-xl);
    line-height: var(--line-height-xxl);
    margin: 36px 0 0 0;
  }

  .hero-subtitle {
    font-size: var(--font-lg);
    line-height: var(--line-height-lg);
    margin: 12px 0 0 0;
  }

  .home-content-container {
    grid-template-columns: 100%;
    margin: 64px 0 0 0;
  }
}

@media (max-width: 576px) {
  .hero {
    font-size: var(--font-lg);
    line-height: var(--line-height-lg);
  }

  .hero-subtitle {
    font-size: var(--font-md);
    line-height: var(--line-height-md);
  }

  .hero-image {
    animation-name: slide-in-from-bottom;
  }

  .home-content-container {
    margin: 48px 0 0 0;
  }
}